<template>
  <div class="content_body Transfer" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="单据号">
              <el-input v-model="TranserBillID" placeholder="输入单据号" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="转出客户">
              <el-input v-model="TransferOutCustomerID" placeholder="名字、手机号或客户编号" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="转账时间">
              <el-date-picker @clear="handleSearch" @change="handleSearch" v-model="SearchData" type="daterange" :picker-options="pickerOptions" unlink-panels range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddApplyDialog" v-prevent-click>客户转账</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData" tooltip-effect="light">
        <el-table-column prop="ID" label="单据号"></el-table-column>
        <el-table-column prop="TransferOutCustomerName" label="转出顾客">
          <template slot-scope="scope">
            <div>{{scope.row.TransferOutCustomerName}}</div>
            <div>手机号：{{scope.row.TransferOutCustomerPhoneNumber}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="TransferInCustomerName" label="转入顾客">
          <template slot-scope="scope">
            <div>{{scope.row.TransferInCustomerName}}</div>
            <div>手机号：{{scope.row.TransferInCustomerPhoneNumber}}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="EntityName" label="操作门店"></el-table-column> -->
        <el-table-column prop="CreatedOn" label="操作时间"></el-table-column>
        <el-table-column prop="CreatedByName" label="操作人"></el-table-column>
        <el-table-column prop="Remark" label="备注信息" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="transerInfo(scope.row)" v-prevent-click>详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="text_right pad_15">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>

    <!-- 添加转账申请 -->
    <el-dialog :visible.sync="addApplyVisible" title="客户转账" width="1280px" :close-on-click-modal="false">
      <div>
        <el-row class="project_content">
          <el-col :span="11" class="project_left pad_10_15 border_right">
            <el-autocomplete popper-class="customer-autocomplete" prefix-icon="el-icon-user-solid" v-model="customerName" style="width:100%" size="small" placeholder="请输入客户姓名、手机号、编号查找" :fetch-suggestions="saleCustomerData" @select="handleCustomerSelect" :disabled="customerID!=null" :trigger-on-focus="false" :hide-loading="true" :highlight-first-item="true" :select-when-unmatched="true">
              <template slot="append">
                <el-button icon="el-icon-delete" @click="removeCustomer"></el-button>
              </template>
              <template slot-scope="{ item }">
                <div class="name">
                  {{ item.Name}}
                  <el-tag size="mini" v-if="item.CustomerLevelName">{{ item.CustomerLevelName }}</el-tag>
                </div>
                <div class="info">手机号：{{ item.PhoneNumber }}</div>
                <span class="info" v-if="item.Code">客户编号：{{ item.Code }}</span>
              </template>
            </el-autocomplete>
          </el-col>
          <el-col :span="13" class="project_right pad_10_15">
            <el-autocomplete popper-class="customer-autocomplete" prefix-icon="el-icon-user-solid" v-model="TransferInCustomerName" style="width:100%" size="small" placeholder="请输入客户姓名、手机号、编号查找" :fetch-suggestions="saleCustomerData" @select="handleCustomerTransferInCustomer" :disabled="TransferInCustomerID!=null" :trigger-on-focus="false" :hide-loading="true" :highlight-first-item="true" :select-when-unmatched="true">
              <template slot="append">
                <el-button icon="el-icon-delete" @click="removeTransferInCustomer"></el-button>
              </template>
              <template slot-scope="{ item }">
                <div class="name">
                  {{ item.Name}}
                  <el-tag size="mini" v-if="item.CustomerLevelName">{{ item.CustomerLevelName }}</el-tag>
                </div>
                <div class="info">手机号：{{ item.PhoneNumber }}</div>
                <span class="info" v-if="item.Code">客户编号：{{ item.Code }}</span>
              </template>
            </el-autocomplete>
          </el-col>
        </el-row>

        <el-row class="project_content">
          <el-col :span="11" class="project_left border_top border_right">
            <div class="pad_0_15">
              <el-input class="martp_15" placeholder="请输入名称、别名" v-model="searchName" size="small" clearable @keyup.enter.native="searchGoodsClick" @clear="clearSearchName" prefix-icon="el-icon-search">
              </el-input>
            </div>
            <el-tabs v-model="tabPane" style="height:50vh" class="martp_15 consumeGoods">
              <el-tab-pane label="项目" name="0" v-if="ProjectCard.length>0">
                <el-scrollbar class="el-scrollbar_height">
                  <el-card class="marbm_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in ProjectCard" :key="index+'PACKAGE'" @click.native="clickItemConsume(item,1)">
                    <div slot="header">
                      <span>{{item.Name}}</span>
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">有效次数：</span>
                          <span>{{item.ValidBalance}}</span>
                          <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>有效次数= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">剩余数量：</span>
                          <span>{{item.Balance}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">退款中数量：</span>
                          <span>{{item.RefundBalance}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">购买价格：</span>
                          <span v-if="item.IsLargess">¥ 0.00</span>
                          <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">购买数量：</span>
                          <span>{{item.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">参考单价：</span>
                          <span>¥ {{item.Amount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">欠款金额：</span>
                          <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="16">
                        <div class="goods-item">
                          <span class="goods-lable">购买日期：</span>
                          <span>{{item.BuyDate}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>

                  <!-- <el-card
                    class="marbm_10  marrt_10 cursor_pointer"
                    :class="index==0?'martp_10':''"
                    :body-style="{ padding: '0px' }"
                    shadow="hover"
                    v-for="(item,index) in ProjectCard"
                    :key="index +'pro'"
                    @click.native="clickItemConsume(item,1)"
                  >
                    <el-col :span="24" class="pad_10_15" style="background-color:#F5F7FA;">
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </el-col>
                    <el-col :span="24">
                      <el-row class="border_top ">
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border">有效次数：<span class="font_color"> {{item.ValidBalance}}</span></el-col>
                          <el-col :span="8" class="col_border">购买次数： <span class="font_color">{{item.Quantity}}</span></el-col>
                           <el-col :span="8" class="col_border dis_flex flex_dir_row" style="border-right:none">
                            退款中数量：
                            <div class="font_color">{{item.RefundBalance }}</div>
                          </el-col>                         

                        </el-row>
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border  flex_dir_row">
                            参考单价：
                            <span class="font_color">￥{{item.Price |NumFormat}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                          总余额：
                          <span class="font_color">￥{{item.Balance | NumFormat}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:none">购买价格：<span class="font_color">￥{{item.TotalAmount | NumFormat}}</span></el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8" class="col_border" >
                            欠款金额：
                            <span class="font_color">￥{{item.ArrearAmount | NumFormat}}</span>
                          </el-col>  
                          <el-col :span="16" class="col_border" style="border-right:none">购买时间：<span class="font_color">{{item.BuyDate}}</span></el-col>
                        </el-row>
                      </el-row>
                    </el-col>
                  </el-card>-->
                </el-scrollbar>
              </el-tab-pane>

              <el-tab-pane label="储值卡" name="2" v-if="Savingcard.length>0">
                <el-scrollbar class="el-scrollbar_height">
                  <el-card class="marbm_10 marrt_10" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in Savingcard" :key="index+'Savingcard'" @click.native="clickItemConsume(item,3)">
                    <div slot="header">
                      <span>{{item.Name}}</span>
                      <span v-if="item.Alias">({{item.Alias}})</span>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">有效余额：</span>
                          <span>¥ {{item.ValidBalance | NumFormat}}</span>
                          <el-popover style="margin-left:4px" placement="top-start" width="240" trigger="hover">
                            <p>1.有效金额= 剩余数量-退款中金额-欠款金额；</p>
                            <p>2.注意：如果有赠额的情况，赠额可用金额也跟退款金额和欠款金额有关；</p>
                            <p>3.比如：充值100送100，欠款50，则可用本金为50，可用赠额为50，可用余额为100。</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">剩余金额：</span>
                          <span>¥ {{item.TotalBalance | NumFormat}}</span>
                          <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>剩余金额= 剩余本金+剩余赠额</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">退款中金额：</span>
                          <span>¥ {{item.RefundAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-col :span="8" class="border_right">
                      <div class="goods-item">
                        <span class="goods-lable">购买金额：</span>
                        <span>¥ {{item.TotalAmount | NumFormat}}</span>
                      </div>
                    </el-col>
                    <el-row class="border_bottom">
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">剩余本金：</span>
                          <span>¥ {{item.Balance | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">剩余赠额：</span>
                          <span>¥ {{item.LargessBalance | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">欠款金额：</span>
                          <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">购买日期：</span>
                          <span>{{item.BuyDate}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">有效期：</span>
                          <span>{{item.ValidDayName}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>

                </el-scrollbar>
              </el-tab-pane>

              <el-tab-pane label="时效卡" name="4" v-if="TimeCard.length>0">
                <el-scrollbar class="el-scrollbar_height">
                  <el-card class="marbm_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="item.IsRefund?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="item.IsRefund?'never':'hover'" v-for="(item,index) in TimeCard" :key="index+'TimeCard'" @click.native="clickItemConsume(item,5)">
                    <div slot="header">
                      <span>{{item.Name}}</span>
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                      <el-tag v-if="item.IsRefund" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">周期内消耗次数：</span>
                          <span>{{item.ConsumeCycleAmount}}</span>
                          <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>当“周期内消耗次数”超过“消耗周期限制”，则不能使用。</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗周期限制：</span>
                          <span>{{(item.ConsumeCycle == 0 || item.CycleLimitAmount ==0)? "不限制":item.CycleLimitAmount + '(次)/' + item.ConsumeCycle + '(天)' }}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">累计耗用次数：</span>
                          <span>{{item.ConsumeAmount}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">购买金额：</span>
                          <span v-if="item.IsLargess">¥ 0.00</span>
                          <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">业绩提成次数：</span>
                          <span>{{item.PerformanceBalance}}</span>
                          <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>用于确认员工消耗业绩。</p>
                            <p>比如：购买金额为1000(如果卡为赠送，则按照售卖价格)，业绩提成次数为10，则每次使用时效卡耗做项目，则1~10次时，员工业绩为100，11次以后（包含第11次），员工业绩为0。</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">参考单价：</span>
                          <span>¥ {{item.Amount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">欠款金额：</span>
                          <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">购买日期：</span>
                          <span>{{item.BuyDate}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">有效期：</span>
                          <span>{{item.ValidDayName }}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-scrollbar>
              </el-tab-pane>
              <el-tab-pane label="通用次卡" name="5" v-if="GeneralCard.length>0">
                <el-scrollbar class="el-scrollbar_height">
                  <el-card class="marbm_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in GeneralCard" :key="index+'GeneralCard'" @click.native="clickItemConsume(item,6)">
                    <div slot="header">
                      <span>{{item.Name}}</span>
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">有效次数：</span>
                          <span>{{item.ValidBalance}}</span>
                          <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>有效次数= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">剩余次数：</span>
                          <span>{{item.Balance}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">退款中数量：</span>
                          <span>{{item.RefundAmount}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">购买金额：</span>
                          <span v-if="item.IsLargess">¥ 0.00</span>
                          <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">卡总次数：</span>
                          <span>{{item.CardTimes}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">参考单价：</span>
                          <span>¥ {{item.Amount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">欠款金额：</span>
                          <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">购买日期：</span>
                          <span>{{item.BuyDate}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">有效期：</span>
                          <span>{{item.ValidDayName}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>

                  <!-- <el-card
                    class="marbm_10 marrt_10 cursor_pointer"
                    :class="index==0?'martp_10':''"
                    :body-style="{ padding: '0px' }"
                    shadow="hover"
                    v-for="(item,index) in GeneralCard"
                    :key="index+'cardgen'"
                    @click.native="clickItemConsume(item,6)"
                  >
                    <el-col :span="24" class="pad_10_15" style="background-color:#F5F7FA;">
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </el-col>
                    <el-col :span="24">
                      <el-row class="border_top">
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border">
                            有效次数：
                            <span class="font_color">{{item.ValidBalance}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border">
                            剩余次数：
                            <span class="font_color">{{item.Balance}}</span>
                          </el-col>
                          <el-col
                            :span="8"
                            class="col_border dis_flex flex_dir_row"
                            style="border-right:none"
                          >
                            卡总次数：
                            <span class="“font_color">{{item.CardTimes}}</span>
                          </el-col>
                        </el-row>
                        <el-row class="border_bottom right">
                          <el-col :span="8" class="col_border">
                            卡总金额：
                            <span class="font_color">￥{{item.TotalAmount | NumFormat}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border">
                            欠款金额：
                            <span class="font_color">￥{{item.ArrearAmount | NumFormat}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:none">
                            参考单价：
                            <span class="font_color">￥{{item.Price | NumFormat}}</span>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                            退款中数量：
                            <div class="font_color">{{item.RefundAmount }}</div>
                          </el-col>
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                            购买时间：
                            <div class="font_color">{{item.BuyDate}}</div>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:none">
                            有效期至：
                            <span class="font_color">{{item.ValidDayName}}</span>
                          </el-col>
                        </el-row>
                      </el-row>
                    </el-col>
                  </el-card>-->
                </el-scrollbar>
              </el-tab-pane>

              <el-tab-pane label="套餐卡" name="3" v-if="PackageCard.length>0">
                <el-row class="category_project valueCard_project">
                  <el-col :span="4" class="text_center category">
                    <el-scrollbar class="el-scrollbar_height border_right border_left">
                      <div v-for="(item,index) in PackageCard" :key="index+'packada'" :class="[packageCategoryIndex == index?'category_select':'','pad_10_15','border_bottom','cursor_pointer']" @click="packageCategoryChange(item,index)">
                        <span class="line_20">{{item.Name}}</span>
                        <span class="line_20" v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" class="marlt_5 line_20" type="danger">赠</el-tag>
                        <el-popover class="marlt_5" popper-class="popover-package" placement="top-start" width="400" trigger="hover">
                          <el-card :body-style="{ padding: '0px' }">
                            <div slot="header">
                              <span>{{item.Name}}</span>
                              <span v-if="item.Alias">({{item.Alias}})</span>
                              <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                            </div>
                            <el-row class="border_bottom">
                              <el-col :span="12" class="border_right">
                                <div class="goods-item">
                                  <span class="goods-lable">购买价格：</span>
                                  <span>¥ {{item.TotalAmount | NumFormat}}</span>
                                </div>
                              </el-col>
                              <el-col :span="12">
                                <div class="goods-item">
                                  <span class="goods-lable">欠款金额：</span>
                                  <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                                </div>
                              </el-col>
                            </el-row>
                            <el-row class="border_bottom">
                              <el-col :span="12" class="border_right">
                                <div class="goods-item">
                                  <span class="goods-lable">购买日期：</span>
                                  <span>{{item.BuyDate}}</span>
                                </div>
                              </el-col>
                              <el-col :span="12">
                                <div class="goods-item">
                                  <span class="goods-lable">有效期：</span>
                                  <span>{{item.ValidDayName}}</span>
                                </div>
                              </el-col>
                            </el-row>
                          </el-card>
                          <el-button type="text" style="color:#dcdfe6;padding:0px" class="font_12" icon="el-icon-info" slot="reference"></el-button>
                        </el-popover>
                      </div>
                    </el-scrollbar>
                  </el-col>
                  <el-col :span="20" class="project" v-loading="packageDetailLoading">
                    <div class="producct_list">
                      <el-scrollbar class="el-scrollbar_height">
                        <!-- 套餐卡产品 -->
                        <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in PackageCardContent.Product" :key="index+'PackageCardContent.Product'" @click.native="packageCardChange(item,1)">
                          <div slot="header">
                            <el-tag size="mini" class="marrt_5">产品</el-tag>
                            <span>{{item.Name}}</span>
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                          </div>
                          <el-row class="border_bottom">
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">有效数量：</span>
                                <span>{{item.ValidBalance}}</span>
                                <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                                  <p>有效数量= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]</p>
                                  <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">剩余数量：</span>
                                <span>{{item.Balance}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">退款中数量：</span>
                                <span>{{item.RefundBalance}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row class="border_bottom">
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">购买价格：</span>
                                <span v-if="item.IsLargess">¥ 0.00</span>
                                <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">购买数量：</span>
                                <span>{{item.Quantity}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">参考单价：</span>
                                <span>¥ {{item.Amount | NumFormat}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">欠款金额：</span>
                                <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">购买日期：</span>
                                <span>{{item.BuyDate}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">有效期：</span>
                                <span>{{item.ValidDayName}}</span>
                              </div>
                            </el-col>
                          </el-row>
                        </el-card>
                        <!-- 套餐卡项目 -->
                        <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in PackageCardContent.Project" :key="index+'PackageCardContent.Project'" @click.native="packageCardChange(item,2)">
                          <div slot="header">
                            <el-tag size="mini" class="marrt_5">项目</el-tag>
                            <span>{{item.Name}}</span>
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                          </div>
                          <el-row class="border_bottom">
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">有效次数：</span>
                                <span>{{item.ValidBalance}}</span>
                                <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                                  <p>有效次数= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]</p>
                                  <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">剩余数量：</span>
                                <span>{{item.Balance}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">退款中数量：</span>
                                <span>{{item.RefundBalance}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row class="border_bottom">
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">购买价格：</span>
                                <span v-if="item.IsLargess">¥ 0.00</span>
                                <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">购买数量：</span>
                                <span>{{item.Quantity}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">参考单价：</span>
                                <span>¥ {{item.Amount | NumFormat}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">欠款金额：</span>
                                <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">购买日期：</span>
                                <span>{{item.BuyDate}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">有效期：</span>
                                <span>{{item.ValidDayName}}</span>
                              </div>
                            </el-col>
                          </el-row>
                        </el-card>
                        <!-- 套餐卡通用次卡 -->
                        <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in PackageCardContent.GeneralCard" :key="index+'PackageCardContent.GeneralCard'" @click.native="packageCardChange(item,3)">
                          <div slot="header">
                            <el-tag size="mini" class="marrt_5">通用次卡</el-tag>
                            <span>{{item.Name}}</span>
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                          </div>
                          <el-row class="border_bottom">
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">有效次数：</span>
                                <span>{{item.ValidBalance}}</span>
                                <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                                  <p>有效次数= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]</p>
                                  <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">剩余次数：</span>
                                <span>{{item.Balance}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">退款中数量：</span>
                                <span>{{item.RefundAmount}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row class="border_bottom">
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">购买金额：</span>
                                <span v-if="item.IsLargess">¥ 0.00</span>
                                <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">卡总次数：</span>
                                <span>{{item.CardTimes}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">参考单价：</span>
                                <span>¥ {{item.Amount | NumFormat}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">欠款金额：</span>
                                <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">购买日期：</span>
                                <span>{{item.BuyDate}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">有效期：</span>
                                <span>{{item.ValidDayName}}</span>
                              </div>
                            </el-col>
                          </el-row>
                        </el-card>
                        <!-- 套餐卡时效卡 -->
                        <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="item.IsRefund?{padding: '0px' ,'background-color': '#F5F7FA' }:{ padding: '0px'}" :shadow="item.IsRefund?'never':'hover'" v-for="(item,index) in PackageCardContent.TimeCard" :key="index+'PackageCardContent.TimeCard'" @click.native="packageCardChange(item,4)">
                          <div slot="header">
                            <el-tag size="mini" class="marrt_5">时效卡</el-tag>
                            <span>{{item.Name}}</span>
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                            <el-tag v-if="item.IsRefund" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                          </div>
                          <el-row class="border_bottom">
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">周期内消耗次数：</span>
                                <span>{{item.ConsumeCycleAmount}}</span>
                                <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                                  <p>当“周期内消耗次数”超过“消耗周期限制”，则不能使用。</p>
                                  <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">消耗周期限制：</span>
                                <span>{{(item.ConsumeCycle == 0 || item.CycleLimitAmount ==0)? "不限制":item.CycleLimitAmount + '(次)/' + item.ConsumeCycle + '(天)' }}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">累计耗用次数：</span>
                                <span>{{item.ConsumeAmount}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row class="border_bottom">
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">购买金额：</span>
                                <span v-if="item.IsLargess">¥ 0.00</span>
                                <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">业绩提成次数：</span>
                                <span>{{item.PerformanceBalance}}</span>
                                <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                                  <p>用于确认员工消耗业绩。</p>
                                  <p>比如：购买金额为1000，业绩提成次数为10，则每次使用时效卡耗做项目，则1~10次时，员工业绩为100，11次以后（包含第11次），员工业绩为0。</p>
                                  <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">参考单价：</span>
                                <span>¥ {{item.Amount | NumFormat}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">欠款金额：</span>
                                <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">购买日期：</span>
                                <span>{{item.BuyDate}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">有效期：</span>
                                <span>{{item.ValidDayName }}</span>
                              </div>
                            </el-col>
                          </el-row>
                        </el-card>
                        <!-- 套餐卡储值卡 -->
                        <el-card class="marbm_10 marrt_10" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in PackageCardContent.SavingCard" :key="index+'Savingcard'" @click.native="packageCardChange(item,5)">
                          <div slot="header">
                            <el-tag size="mini" class="marrt_5">储值卡</el-tag>
                            <span>{{item.Name}}</span>
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                          </div>
                          <el-row class="border_bottom">
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">有效余额：</span>
                                <span>¥ {{item.ValidBalance | NumFormat}}</span>
                                <el-popover style="margin-left:4px" placement="top-start" width="240" trigger="hover">
                                  <p>1.有效金额= 剩余数量-退款中金额-欠款金额；</p>
                                  <p>2.注意：如果有赠额的情况，赠额可用金额也跟退款金额和欠款金额有关；</p>
                                  <p>3.比如：充值100送100，欠款50，则可用本金为50，可用赠额为50，可用余额为100。</p>
                                  <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">剩余金额：</span>
                                <span>¥ {{item.TotalBalance | NumFormat}}</span>
                                <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                                  <p>剩余金额= 剩余本金+剩余赠额</p>
                                  <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">退款中金额：</span>
                                <span>¥ {{item.RefundAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-col :span="8" class="border_right">
                            <div class="goods-item">
                              <span class="goods-lable">购买金额：</span>
                              <span>¥ {{item.TotalAmount | NumFormat}}</span>
                            </div>
                          </el-col>
                          <el-row class="border_bottom">
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">剩余本金：</span>
                                <span>¥ {{item.Balance | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">剩余赠额：</span>
                                <span>¥ {{item.LargessBalance | NumFormat}}</span>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="8" class="border_right">
                              <div class="goods-item">
                                <span class="goods-lable">欠款金额：</span>
                                <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <div class="goods-item">
                                <span class="goods-lable">购买日期：</span>
                                <span>{{item.BuyDate}}</span>
                              </div>
                            </el-col>
                            <el-col :span="8" class="border_left">
                              <div class="goods-item">
                                <span class="goods-lable">有效期：</span>
                                <span>{{item.ValidDayName}}</span>
                              </div>
                            </el-col>
                          </el-row>
                        </el-card>
                      </el-scrollbar>
                    </div>
                  </el-col>
                </el-row>

              </el-tab-pane>

              <el-tab-pane label="产品" name="1" v-if="ProductCard.length>0">
                <el-scrollbar class="el-scrollbar_height">
                  <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index==0?'martp_10':''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in ProductCard" :key="index+'cardProduct'" @click.native="clickItemConsume(item,2)">
                    <div slot="header">
                      <span>{{item.Name}}</span>
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">有效数量：</span>
                          <span>{{item.ValidBalance}}</span>
                          <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                            <p>有效数量= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]</p>
                            <el-button type="text" style="color:#dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                          </el-popover>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">剩余数量：</span>
                          <span>{{item.Balance}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">退款中数量：</span>
                          <span>{{item.RefundBalance}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">购买价格：</span>
                          <span v-if="item.IsLargess">¥ 0.00</span>
                          <span v-else>¥ {{item.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">购买数量：</span>
                          <span>{{item.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">参考单价：</span>
                          <span>¥ {{item.Amount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">欠款金额：</span>
                          <span>¥ {{item.ArrearAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                      <el-col :span="16">
                        <div class="goods-item">
                          <span class="goods-lable">购买日期：</span>
                          <span>{{item.BuyDate}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>

                  <!-- <el-card
                    class="marbm_10 marrt_10 cursor_pointer"
                    :class="index==0?'martp_10':''"
                    :body-style="{ padding: '0px' }"
                    shadow="hover"
                    v-for="(item,index) in ProductCard"
                    :key="index+'produ'"
                    @click.native="clickItemConsume(item,2)"
                  >
                    <el-col :span="24" class="pad_10_15" style="background-color:#F5F7FA;">
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </el-col>
                    <el-col :span="24">
                      <el-row class="border_top">
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border">
                            有效次数：
                            <span class="font_color">{{item.ValidBalance}}</span>
                          </el-col>
                          <el-col :span="8" class="col_border">
                            购买次数：
                            <span class="font_color">{{item.Quantity}}</span>
                          </el-col>
                          <el-col
                            :span="8"
                            class="col_border dis_flex flex_dir_row"
                            style="border-right:none"
                          >
                            退款中次数：
                            <div class="font_color">{{item.RefundBalance }}</div>
                          </el-col>
                        </el-row>
                        <el-row class="border_bottom">
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                            参考单价：
                            <div class="font_color">￥{{item.Price | NumFormat}}</div>
                          </el-col>
                          <el-col :span="8" class="col_border dis_flex flex_dir_row">
                            总余额：
                            <div class="font_color">￥{{item.Balance | NumFormat}}</div>
                          </el-col>
                          <el-col :span="8" class="col_border" style="border-right:none">
                            购买价格：
                            <span class="font_color">￥{{item.TotalAmount | NumFormat}}</span>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8" class="col_border">
                            欠款金额：
                            <span class="font_color">￥{{item.ArrearAmount | NumFormat}}</span>
                          </el-col>

                          <el-col :span="16" class="col_border" style="border-right:none">
                            购买时间：
                            <span class="font_color">{{item.BuyDate}}</span>
                          </el-col>
                        </el-row>
                      </el-row>
                    </el-col>
                  </el-card>-->
                </el-scrollbar>
              </el-tab-pane>
            </el-tabs>
          </el-col>
          <el-col :span="13" class="project_right border_top">
            <el-main style="height:52vh">
              <el-scrollbar class="el-scrollbar_height">
                <!-- 项目  -->
                <div v-if="ProjectCardList.length>0">
                  <el-row class="row_header border_bottom">
                    <el-col :span="9">项目</el-col>
                    <el-col :span="5">有效次数</el-col>
                    <el-col :span="9">转入次数</el-col>
                    <el-col :span="1">操作</el-col>
                  </el-row>
                  <el-row class="border_bottom" v-for="(item,index) in ProjectCardList" :key="index+ 'ject'">
                    <el-col :span="24" class="pad_10 dis_flex flex_y_center">
                      <el-col :span="9">
                        {{item.Name}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                      </el-col>
                      <el-col :span="5">x {{item.ValidBalance}}</el-col>
                      <el-col :span="9">
                        <el-input class="itemWidth" type="number" :min="0" :max="item.ValidBalance" @input="((val)=>{changeValue(val, index,item)})" v-model="item.ComeQuantity" placeholder="请输入转入次数" size="small"></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(index,ProjectCardList)"></el-button>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
                <!--储值卡-->
                <div v-if="SavingCardList.length>0">
                  <el-row class="row_header border_bottom">
                    <el-col :span="9">储值卡</el-col>
                    <el-col :span="5">有效金额</el-col>
                    <el-col :span="9">转入余量（本金/赠额）</el-col>
                    <el-col :span="1">操作</el-col>
                  </el-row>
                  <el-row class="border_bottom" v-for="(item,index) in SavingCardList" :key="index +'save'">
                    <el-col :span="24" class="pad_10 dis_flex flex_y_center">
                      <el-col :span="9">
                        {{item.Name}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                      </el-col>
                      <el-col :span="5">
                        <div>本金：￥{{item.AllowRefundBalance | NumFormat }}</div>
                        <div>赠金：￥{{item.AllowRefundLargessBalance | NumFormat}}</div>
                      </el-col>
                      <el-col :span="9">
                        <el-input style="width:45%" type="number" :min="0" :max="item.AllowRefundBalance" v-model="item.Amount" @input="((val)=>{changeSavingCardValue(val, index,item)})" placeholder="请输入本金" size="small"></el-input>
                        <el-input style="width:45%" type="number" :min="0" @input="((val)=>{changeSavingCardLargessValue(val, index,item)})" :max="item.AllowRefundLargessBalance" v-model="item.LargessAmount" placeholder="请输入赠金" size="small"></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(index,SavingCardList)"></el-button>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
                <!--时效卡-->
                <div v-if="TimeCardList.length>0">
                  <el-row class="row_header border_bottom">
                    <el-col :span="9">时效卡</el-col>
                    <el-col :span="14">有效业绩次数</el-col>
                    <el-col :span="1">操作</el-col>
                  </el-row>
                  <el-row class="border_bottom" v-for="(item,index) in TimeCardList" :key="index+'time'">
                    <el-col :span="24" class="pad_10 dis_flex flex_y_center">
                      <el-col :span="9">
                        {{item.Name}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                      </el-col>
                      <el-col :span="5">x {{item.ValidBalance}}</el-col>
                      <el-col :span="9"></el-col>
                      <el-col :span="1">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(index,TimeCardList)"></el-button>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
                <!--通用次卡-->
                <div v-if="GeneralCardList.length>0">
                  <el-row class="row_header border_bottom">
                    <el-col :span="9">通用次卡</el-col>
                    <el-col :span="5">有效存量</el-col>
                    <el-col :span="9">转入余量</el-col>
                    <el-col :span="1">操作</el-col>
                  </el-row>
                  <el-row class="border_bottom" v-for="(item,index) in GeneralCardList" :key="index+'genral'">
                    <el-col :span="24" class="pad_10 dis_flex flex_y_center">
                      <el-col :span="9">
                        {{item.Name}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                      </el-col>
                      <el-col :span="5">x {{item.ValidBalance}}</el-col>
                      <el-col :span="9">
                        <el-input class="itemWidth" type="number" :min="0" :max="item.ValidBalance" v-model="item.ComeQuantity" @input="((val)=>{changeValue(val, index,item)})" placeholder="请输入转入次数" size="small"></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(index,GeneralCardList)"></el-button>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
                <!--套餐卡-->
                <div v-if="PackageCardList.length >0">
                  <el-row class="row_header border_bottom">
                    <el-col :span="9">套餐卡</el-col>
                    <el-col :span="5">有效存量</el-col>
                    <el-col :span="9">转入余量</el-col>
                    <el-col :span="1">操作</el-col>
                  </el-row>
                  <el-row v-for="(item,index) in PackageCardList" :key="index+'packlist'">
                    <el-col :span="24" class="pad_10 border_bottom backf5f7fa">
                      {{item.Name}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                    </el-col>
                    <el-col class="pad_10 border_bottom dis_flex flex_y_center" v-for="(cardList,listIndex) in item.ChildItems" :key="listIndex+'listIndex'">
                      <el-col :span="9">
                        <span>{{cardList.Name}}</span>
                        <span v-if="cardList.Alias">({{cardList.Alias}})</span>
                        <el-tag size="mini" class="marlt_5 font_12" effect="plain">{{cardList.CardType}}</el-tag>
                      </el-col>
                      <el-col :span="5" v-if="cardList.CardType =='储值卡'?false:true">x {{cardList.ValidBalance}}</el-col>
                      <el-col :span="5" v-if="cardList.CardType =='储值卡'?true:false">
                        <div>本金：￥{{cardList.AllowRefundBalance | NumFormat}}</div>
                        <div>赠金：￥{{cardList.AllowRefundLargessBalance | NumFormat}}</div>
                      </el-col>
                      <el-col :span="9" v-if="cardList.CardType =='储值卡'?false:true">
                        <el-input v-if="cardList.CardType=='时效卡'?false:true" class="itemWidth" type="number" :min="0" :max="cardList.ValidBalance" v-model="cardList.ComeQuantity" @input="((val)=>{changeValue(val, index,cardList)})" placeholder="请输入转入次数" size="small"></el-input>
                      </el-col>
                      <el-col :span="9" v-if="cardList.CardType =='储值卡'?true:false">
                        <el-input style="width:45%" type="number" :min="0" :max="cardList.AllowRefundBalance" v-model="cardList.Amount" @input="((val)=>{changeSavingCardValue(val, index,cardList)})" placeholder="请输入本金" size="small"></el-input>
                        <el-input style="width:45%" type="number" :min="0" :max="cardList.AllowRefundLargessBalance" v-model="cardList.LargessAmount" @change="((val)=>{changeSavingCardLargessValue(val, index,cardList)})" placeholder="请输入赠金" size="small"></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageClick(item,index,item.ChildItems,listIndex)"></el-button>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
                <!-- 产品 -->
                <div v-if="ProductCardList.length>0">
                  <el-row class="row_header border_bottom">
                    <el-col :span="9">产品</el-col>
                    <el-col :span="5">有效次数</el-col>
                    <el-col :span="9">转入次数</el-col>
                    <el-col :span="1">操作</el-col>
                  </el-row>
                  <el-row class="border_bottom" v-for="(item,index) in ProductCardList" :key="index +'duct+'">
                    <el-col :span="24" class="pad_10 dis_flex flex_y_center">
                      <el-col :span="9">
                        {{item.Name}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                      </el-col>
                      <el-col :span="5">x {{item.ValidBalance}}</el-col>
                      <el-col :span="9">
                        <el-input class="itemWidth" type="number" :min="0" :max="item.ValidBalance" v-model="item.ComeQuantity" @input="((val)=>{changeValue(val, index,item)})" placeholder="请输入转入次数" size="small"></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(index,ProductCardList)"></el-button>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
              </el-scrollbar>
            </el-main>

            <el-footer class="border_top">
              <el-row type="flex" align="middle" justify="space-between">
                <el-col :span="2">备注信息</el-col>
                <el-col :span="15">
                  <el-input type="textarea" size="small" :rows="1" placeholder="请输入备注信息" v-model="Remark"></el-input>
                </el-col>
                <el-col :span="7" class="text_right">
                  <el-button size="small" @click="goBack">取消</el-button>
                  <el-button type="primary" :loading="submitLoading" size="small" @click="SaveTranserCreate">确认转账</el-button>
                </el-col>
              </el-row>
            </el-footer>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog :visible.sync="applyDetailVisible" width="1000px">
      <span slot="title">
        <span>转账详情</span>
      </span>
      <!-- //订单详情弹窗 -->
      <div style="height:60vh" class="font_13">
        <el-scrollbar class="el-scrollbar_height">
          <div style="margin-right:10px" class="marbm_10">
            <el-form label-width="100px" size="small" class="transfer">
              <div class="tip" style="margin-top:0">转账信息</div>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="订单编号:">{{transDetail.ID}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="操作人:">{{transDetail.CreatedByName}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="操作时间:">{{transDetail.CreatedOn}}</el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row>
                <el-col :span="8">
                  <el-form-item label="操作门店:">{{transDetail.EntityName}}</el-form-item>
                </el-col>
              </el-row> -->
              <el-row>
                <el-col :span="8">
                  <el-form-item label="转出顾客:">
                    {{transDetail.TransferOutCustomerName}}
                    <span>({{transDetail.TransferOutCustomerPhoneNumber}})</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="转入顾客:">
                    {{transDetail.TransferInCustomerName}}
                    <span>({{transDetail.TransferInCustomerPhoneNumber}})</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="备注信息:">{{transDetail.Remark}}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tip">转账信息</div>

          <div class="border_right border_left border_top">
            <el-row class="row_Detalheader border_bottom" v-if="transDetail.Project != undefined && transDetail.Project.length>0">
              <el-col :span="12">项目</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in transDetail.Project" :key="item.ProjectAccountID">
              <el-col :span="24" class="pad_10">
                <el-col :span="24">
                  <el-col :span="12">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="12" v-if="item.Quantity">x {{item.Quantity}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader border_bottom" v-if="transDetail.PackageCard!= undefined && transDetail.PackageCard.length>0">
              <el-col :span="12">套餐卡</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row class v-for="item in transDetail.PackageCard" :key="item.PackageCardAccountID">
              <el-row class="pad_10 border_bottom backf5f7fa">
                <el-col :span="12">
                  {{item.Name}}
                  <span v-if="item.Alias">({{item.Alias}})</span>
                </el-col>
                <el-col :span="12" v-if="item.Quantity">x {{item.Quantity}}</el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.Project" :key="index+'listPRO'" class="border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_5">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">项目</el-tag>
                </el-col>
                <el-col :span="12" class="martp_5">x {{packList.Quantity}}</el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.Product" :key="index+'listduc'" class="border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_5">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">产品</el-tag>
                </el-col>
                <el-col :span="12" class="martp_5">x {{packList.Quantity}}</el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.GeneralCard" :key="index+'listgen'" class="border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_5">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">通用次卡</el-tag>
                </el-col>
                <el-col :span="12" class="martp_5">x {{packList.Quantity}}</el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.TimeCard" :key="index+'listtime'" class="border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_5">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">时效卡</el-tag>
                </el-col>
                <el-col :span="12"></el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.SavingCard" :key="index+'listsave'" class=" border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_12">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">储值卡</el-tag>
                </el-col>
                <el-col :span="12" class="martp_5">
                  <div>本金：￥{{packList.Amount |NumFormat}}</div>
                  <div>赠金：￥{{packList.LargessAmount | NumFormat}}</div>
                </el-col>
              </el-row>
            </el-row>
            <el-row class="row_Detalheader border_bottom" v-if="transDetail.SavingCard!= undefined && transDetail.SavingCard.length>0">
              <el-col :span="12">储值卡</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row v-for="item in transDetail.SavingCard" :key="item.SavingCardAccountID">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="12" class="martp_10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="12">
                    <div>本金：￥{{item.Amount | NumFormat}}</div>
                    <div>赠金：￥{{item.LargessAmount | NumFormat}}</div>
                  </el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader border_bottom" v-if="transDetail.Product!=undefined && transDetail.Product.length >0">
              <el-col :span="12">产品</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in transDetail.Product" :key="item.ProductAccountID">
              <el-col :span="24" class="pad_10">
                <el-col :span="24">
                  <el-col :span="12">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="12" v-if="item.Quantity">x {{item.Quantity}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader border_bottom" v-if="transDetail.GeneralCard != undefined && transDetail.GeneralCard.length>0">
              <el-col :span="12">通用次卡</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in transDetail.GeneralCard" :key="item.GeneralCardAccountID">
              <el-col :span="24" class="pad_10">
                <el-col :span="12">
                  {{item.Name}}
                  <span v-if="item.Alias">({{item.Alias}})</span>
                </el-col>
                <el-col :span="12" v-if="item.Quantity">x {{item.Quantity}}</el-col>
              </el-col>
            </el-row>

            <el-row class="row_Detalheader border_bottom" v-if="transDetail.TimeCard !=undefined && transDetail.TimeCard.length>0">
              <el-col :span="12">时效卡</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row v-for="item in transDetail.TimeCard" :key="item.TimeCardAccountID">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="12">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="12" v-if="item.Quantity">x {{item.Quantity}}</el-col>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </div>
    </el-dialog>

  </div>
</template>
  
  <script>
import API from "@/api/iBeauty/PostponeTransfer/transfer";
import date from "@/components/js/date";

var Enumerable = require("linq");

export default {
  name: "Transfer",
  data() {
    return {
      tabPane: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loading: false,
      submitLoading: false,
      packageDetailLoading: false,
      ID: "",
      TranserBillID: "",
      TransferOutCustomerID: "",
      SearchData: "",
      EndDate: "",
      StartDate: "",
      tableData: [],
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      Remark: "",
      packageCategoryIndex: 0,
      ProjectCardList: [],
      ProductCardList: [],
      SavingCardList: [],
      GeneralCardList: [],
      TimeCardList: [],
      PackageCardList: [],
      PackageCardContent: "",
      ProductCard: [],
      ProjectCard: [],
      Savingcard: [],
      GeneralCard: [],
      TimeCard: [],
      PackageCard: [],
      transDetail: "",
      searchName: "",
      addApplyVisible: false,
      applyDetailVisible: false,
      withdrawDialog: false,
      Istype: false,
      customerName: "",
      customerID: null,
      customerFullName: "",
      customerPhoneNumber: "",
      TransferInCustomerID: null,
      TransferInCustomerFullName: "",
      TransferInCustomerPhoneNumber: "",
      TransferInCustomerName: "",

      currentDate: new Date(),
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.paginations.page = page;
      this.transerAcount();
    },
    handleSearch: function () {
      var that = this;
      that.paginations.page = 1;
      that.transerAcount();
    },
    /** 添加转账  */
    showAddApplyDialog() {
      var that = this;
      that.tabPane = "";
      that.customerID = null;
      that.customerFullName = "";
      that.customerPhoneNumber = "";
      that.customerName = "";
      that.TransferInCustomerID = null;
      that.TransferInCustomerFullName = "";
      that.TransferInCustomerPhoneNumber = "";
      that.TransferInCustomerName = "";
      that.Remark = "";
      that.ProjectCardList = [];
      that.ProductCardList = [];
      that.SavingCardList = [];
      that.GeneralCardList = [];
      that.TimeCardList = [];
      that.PackageCardList = [];
      that.ProductCard = [];
      that.ProjectCard = [];
      that.Savingcard = [];
      that.TimeCard = [];
      that.GeneralCard = [];
      that.PackageCard = [];
      that.PackageCardContent = "";
      that.addApplyVisible = true;
    },

    //转账列表
    transerAcount() {
      var that = this;
      if (that.SearchData == null || that.SearchData == "") {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.SearchData[0];
        that.EndDate = that.SearchData[1];
      }
      var params = {
        PageNum: that.paginations.page,
        TranserBillID: that.TranserBillID,
        TransferOutCustomerID: that.TransferOutCustomerID,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
      };
      API.transerAcount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //转账详情
    transerInfo(row) {
      var that = this;
      that.ID = row.ID;
      var params = {
        ID: that.ID,
      };
      API.transerInfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.transDetail = res.Data;
            that.applyDetailVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /** 顾客    */
    saleCustomerData: function (queryString, cb) {
      var that = this;
      that.loading = true;
      var params = {
        Name: queryString ? queryString : "",
      };
      API.getSaleCustomer(params)
        .then((res) => {
          if (res.StateCode == 200) {
            cb(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  选择顾客  */
    handleCustomerSelect(item) {
      var that = this;
      if (item.ID != undefined) {
        that.customerID = item.ID;
        that.customerFullName = item.Name;
        that.tabPane = "";
        that.customerPhoneNumber = item.PhoneNumber;
        that.customerName = item.Name + "【" + item.PhoneNumber + "】";
        that.TranserAccountProjectAccount();
        that.TranserAccountProductAccount();
        that.TranserAccountSavingCardAccount();
        that.TranserAccountGeneralCardAccount();
        that.TranserAccountTimeCardAccount();
        that.TranserAccountPackageCardAccount();
      }
    },
    //选择转入会员
    handleCustomerTransferInCustomer(item) {
      var that = this;
      if (item.ID === that.customerID) {
        that.$message.error({
          message: "不可以选同一个客户",
          duration: 2000,
          customClass: "zZindex",
        });
      } else {
        if (item.ID != undefined) {
          that.TransferInCustomerID = item.ID;
          that.TransferInCustomerFullName = item.Name;
          that.TransferInCustomerPhoneNumber = item.PhoneNumber;
          that.TransferInCustomerName =
            item.Name + "【" + item.PhoneNumber + "】";
        }
      }
    },
    searchGoodsClick() {
      var that = this;
      if (that.customerID == null || that.customerID == "") {
        that.$message.error({
          message: "请选择顾客",
          duration: 2000,
        });
      } else {
        that.tabPane = "";
        that.TranserAccountProjectAccount();
        that.TranserAccountProductAccount();
        that.TranserAccountSavingCardAccount();
        that.TranserAccountGeneralCardAccount();
        that.TranserAccountTimeCardAccount();
        that.TranserAccountPackageCardAccount();
      }
    },
    clearSearchName() {
      var that = this;
      that.searchGoodsClick();
    },
    removeTransferInCustomer() {
      var that = this;
      that.TransferInCustomerID = null;
      that.TransferInCustomerFullName = "";
      that.TransferInCustomerPhoneNumber = "";
      that.TransferInCustomerName = "";
    },
    removeCustomer() {
      this.customerID = null;
      this.customerFullName = "";
      this.customerPhoneNumber = "";
      this.customerName = "";
      (this.ProjectCardList = []),
        (this.ProductCardList = []),
        (this.SavingCardList = []),
        (this.GeneralCardList = []),
        (this.TimeCardList = []),
        (this.PackageCardList = []);
      (this.ProductCard = []),
        (this.ProjectCard = []),
        (this.Savingcard = []),
        (this.TimeCard = []),
        (this.GeneralCard = []),
        (this.PackageCard = []),
        (this.PackageCardContent = "");
      //  this.customerChange();
    },
    //转账项目存量列表
    TranserAccountProjectAccount() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.searchName,
      };
      API.TranserAccountProjectAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ProjectCard = res.Data;
            if (that.tabPane == "" && that.ProjectCard.length > 0) {
              that.tabPane = "0";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //转账转账产品存量列表
    TranserAccountProductAccount() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.searchName,
      };
      API.TranserAccountProductAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ProductCard = res.Data;
            if (that.tabPane == "" && that.ProductCard.length > 0) {
              that.tabPane = "1";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //转账储值卡存量列表
    TranserAccountSavingCardAccount() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.searchName,
      };
      API.TranserAccountSavingCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.Savingcard = res.Data;
            if (that.tabPane == "" && that.Savingcard.length > 0) {
              that.tabPane = "2";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //转账通用次卡存量列表
    TranserAccountGeneralCardAccount() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.searchName,
      };
      API.TranserAccountGeneralCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.GeneralCard = res.Data;
            if (that.tabPane == "" && that.GeneralCard.length > 0) {
              that.tabPane = "5";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //转账时效卡存量列表
    TranserAccountTimeCardAccount() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.searchName,
      };
      API.TranserAccountTimeCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.TimeCard = res.Data;
            if (that.tabPane == "" && that.TimeCard.length > 0) {
              that.tabPane = "4";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //转账套餐卡存量列表
    TranserAccountPackageCardAccount() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        Name: that.searchName,
      };
      API.TranserAccountPackageCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.PackageCard = res.Data;
            if (that.tabPane == "" && that.PackageCard.length > 0) {
              that.tabPane = "3";
            }
            if (that.PackageCard.length > 0) {
              if (that.PackageCardDetail == undefined) {
                that.PackageCardDetail = that.PackageCard[0];
              }
              that.TranserAccountPackageCardAccountDetails(
                that.PackageCard[0].ID
              );
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //套餐卡二级列表
    TranserAccountPackageCardAccountDetails(ID, item) {
      var that = this;
      var params = {
        PackageCardAccountID: ID,
      };
      that.packageDetailLoading = true;
      API.TranserAccountPackageCardAccountDetails(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.PackageCardContent = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.packageDetailLoading = false;
        });
    },
    /**  添加套餐卡明细 转账  */
    packageCardChange(item, type) {
      var that = this;
      var isPush = false;
      var packageProdctItem = Enumerable.from(that.PackageCardList)
        .where((i) => {
          return i.ID == that.PackageCardDetail.ID;
        })
        .firstOrDefault(-1, "$");
      if (packageProdctItem == -1) {
        isPush = true;
        packageProdctItem = {
          ID: that.PackageCardDetail.ID,
          PackageCardID: that.PackageCardDetail.PackageCardID,
          Name: that.PackageCardDetail.Name,
          IsLargess: that.PackageCardDetail.IsLargess,
          Alias: that.PackageCardDetail.Alias,
          ChildItems: [],
        };
      }
      var ChildItems = Enumerable.from(packageProdctItem.ChildItems)
        .where((i) => {
          return i.ID == item.ID;
        })
        .firstOrDefault(-1, "$");

      switch (type) {
        case 1:
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "产品有效次数不足",
              duration: 2000,
            });
            return;
          }
          if (ChildItems != -1) {
            that.$message.error({
              message: "产品已存在",
              duration: 2000,
            });
            return;
          }

          packageProdctItem.ChildItems.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            ProductID: item.ProductID,
            Quantity: item.Quantity,
            RefundBalance: item.RefundBalance,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ComeQuantity: item.ValidBalance,
            CardType: "产品",
          });

          break;
        case 2:
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "项目有效次数不足",
              duration: 2000,
            });
            return;
          }
          if (ChildItems != -1) {
            that.$message.error({
              message: "项目已存在",
              duration: 2000,
            });
            return;
          }
          packageProdctItem.ChildItems.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            ProjectID: item.ProjectID,
            Quantity: item.Quantity,
            RefundBalance: item.RefundBalance,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ComeQuantity: item.ValidBalance,
            CardType: "项目",
          });

          break;
        case 3:
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "通用次卡有效次数不足",
              duration: 2000,
            });
            return;
          }

          if (ChildItems != -1) {
            that.$message.error({
              message: "通用次卡已存在",
              duration: 2000,
            });
            return;
          }
          packageProdctItem.ChildItems.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            CardTimes: item.CardTimes,
            GeneralCardID: item.GeneralCardID,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            RefundAmount: item.RefundAmount,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            ComeQuantity: item.ValidBalance,
            CardType: "通用次卡",
          });
          break;
        case 4:
          if (item.IsRefund) return;
          if (item.PerformanceBalance <= 0) {
            that.$message.error({
              message: "时效卡剩余业绩次数不足",
              duration: 2000,
            });
            return false;
          }

          if (ChildItems != -1) {
            that.$message.error({
              message: "套餐卡 - 时效卡已存在",
              duration: 2000,
            });
            return;
          }
          packageProdctItem.ChildItems.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            BuyDate: item.BuyDate,
            ConsumeAmount: item.ConsumeAmount,
            ConsumeCycle: item.ConsumeCycle,
            ConsumeCycleAmount: item.ConsumeCycleAmount,
            CycleLimitAmount: item.CycleLimitAmount,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            PerformanceBalance: item.PerformanceBalance,
            PerformanceBalanceAmount: item.PerformanceBalanceAmount,
            PerformanceTimes: item.PerformanceTimes,
            Price: item.Price,
            TimeCardID: item.TimeCardID,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            ComeQuantity: item.ValidBalance,
            CardType: "时效卡",
          });

          break;
        case 5:
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "有效余额不足",
              duration: 2000,
            });
            return false;
          }

          if (ChildItems != -1) {
            that.$message.error({
              message: "套餐卡 - 储值卡已存在",
              duration: 2000,
            });
            return;
          }

          packageProdctItem.ChildItems.push({
            AccountID: item.AccountID,
            Alias: item.Alias,
            AllowRefundBalance: item.AllowRefundBalance,
            AllowRefundLargessBalance: item.AllowRefundLargessBalance,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            LargessBalance: item.LargessBalance,
            Name: item.Name,
            RefundAmount: item.RefundAmount,
            SavingCardID: item.SavingCardID,
            TotalAmount: item.TotalAmount,
            TotalBalance: item.TotalBalance,
            Type: item.Type,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            Amount: item.AllowRefundBalance,
            LargessAmount: item.AllowRefundLargessBalance,
            CardType: "储值卡",
          });

          break;
      }
      if (isPush) {
        that.PackageCardList.push(packageProdctItem);
      }
    },
    //项目 产品 通用次卡
    changeValue(value, index, item) {
      var that = this;
      if (value > item.ValidBalance) {
        // that.$message.error({
        //   message: "转入余量不能大于有效余量",
        //   duration: 2000,
        // });
        item.ComeQuantity = item.ValidBalance;
      }
    },
    //储值卡
    changeSavingCardValue(value, index, item) {
      var that = this;
      if (value > item.AllowRefundBalance) {
        // that.$message.error({
        //   message: "本金不能大于有效余量本金",
        //   duration: 2000,
        // });
        item.Amount = item.AllowRefundBalance;
      }
    },
    changeSavingCardLargessValue(value, index, item) {
      var that = this;
      if (value > item.AllowRefundLargessBalance) {
        // that.$message.error({
        //   message: "赠金不能大于有效余量赠金",
        //   duration: 2000,
        // });
        item.LargessAmount = item.AllowRefundLargessBalance;
      }
    },
    //选择卡项
    clickItemConsume(item, type) {
      var that = this;
      switch (type) {
        case 1:
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "项目有效次数不足",
              duration: 2000,
            });
            return false;
          }
          if (
            Enumerable.from(that.ProjectCardList).contains(item.ID, (i) => i.ID)
          ) {
            that.$message.error({
              message: "项目已存在",
              duration: 2000,
            });
            return;
          }
          that.ProjectCardList.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            ProjectID: item.ProjectID,
            Quantity: item.Quantity,
            RefundBalance: item.RefundBalance,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ComeQuantity: item.ValidBalance,
          });

          break;
        case 2:
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "产品有效次数不足",
              duration: 2000,
            });
            return;
          }

          if (
            Enumerable.from(that.ProductCardList).contains(item.ID, (i) => i.ID)
          ) {
            that.$message.error({
              message: "产品已存在",
              duration: 2000,
            });
            return;
          }

          that.ProductCardList.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            ProductID: item.ProductID,
            Quantity: item.Quantity,
            RefundBalance: item.RefundBalance,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ComeQuantity: item.ValidBalance,
          });

          break;
        case 3:
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "储值卡有效余额不足",
              duration: 2000,
            });
            return;
          }

          if (
            Enumerable.from(that.SavingCardList).contains(item.ID, (i) => i.ID)
          ) {
            that.$message.error({
              message: "储值卡已存在",
              duration: 2000,
            });
            return;
          }

          that.SavingCardList.push({
            AccountID: item.AccountID,
            Alias: item.Alias,
            AllowRefundBalance: item.AllowRefundBalance,
            AllowRefundLargessBalance: item.AllowRefundLargessBalance,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            LargessBalance: item.LargessBalance,
            Name: item.Name,
            RefundAmount: item.RefundAmount,
            SavingCardID: item.SavingCardID,
            TotalAmount: item.TotalAmount,
            TotalBalance: item.TotalBalance,
            Type: item.Type,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            Amount: item.AllowRefundBalance,
            LargessAmount: item.AllowRefundLargessBalance,
          });

          break;
        case 4:
          break;
        case 5:
          if (item.IsRefund) return;

          if (item.PerformanceBalance <= 0) {
            that.$message.error({
              message: "时效卡-剩余业绩次数不足",
              duration: 2000,
            });
            return false;
          }
          if (
            Enumerable.from(that.TimeCardList).contains(item.ID, (i) => i.ID)
          ) {
            that.$message.error({
              message: "时效卡已存在",
              duration: 2000,
            });
            return;
          }

          that.TimeCardList.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            BuyDate: item.BuyDate,
            ConsumeAmount: item.ConsumeAmount,
            ConsumeCycle: item.ConsumeCycle,
            ConsumeCycleAmount: item.ConsumeCycleAmount,
            CycleLimitAmount: item.CycleLimitAmount,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            PerformanceBalance: item.PerformanceBalance,
            PerformanceBalanceAmount: item.PerformanceBalanceAmount,
            PerformanceTimes: item.PerformanceTimes,
            Price: item.Price,
            TimeCardID: item.TimeCardID,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            ComeQuantity: item.ValidBalance,
          });

          break;
        case 6:
          if (item.ValidBalance <= 0) {
            that.$message.error({
              message: "有效次数不足",
              duration: 2000,
            });
            return false;
          }
          if (
            Enumerable.from(that.GeneralCardList).contains(item.ID, (i) => i.ID)
          ) {
            that.$message.error({
              message: "通用次卡 - 已存在",
              duration: 2000,
            });
            return;
          }

          that.GeneralCardList.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            CardTimes: item.CardTimes,
            GeneralCardID: item.GeneralCardID,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            RefundAmount: item.RefundAmount,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            ComeQuantity: item.ValidBalance,
          });

          break;
      }
    },
    // 套餐卡一级分类
    packageCategoryChange: function (item, index) {
      var that = this;
      that.PackageCardDetail = item;
      that.TranserAccountPackageCardAccountDetails(item.ID, item);
      that.packageCategoryIndex = index;
    },
    //删除套餐卡列表
    removePackageClick(packageCard, packIndex, list, index) {
      var that = this;
      if (packageCard.ChildItems.length == 1) {
        that.PackageCardList.splice(packIndex, 1);
      } else {
        list.splice(index, 1);
      }
    },
    //删除右边卡项
    removeClick(index, List) {
      List.splice(index, 1);
    },
    //会员转账
    TranserCreate() {
      var that = this;
      that.submitLoading = true;

      var params = {
        TransferOutCustomerID: that.customerID,
        TransferInCustomerID: that.TransferInCustomerID,
        Remark: that.Remark,
        Product: [],
        Project: [],
        GeneralCard: [],
        TimeCard: [],
        SavingCard: [],
        PackageCard: [],
      };

      for (let index = 0; index < that.PackageCardList.length; index++) {
        let element = that.PackageCardList[index];
        /**  项目  */
        var packageProject = Enumerable.from(element.ChildItems)
          .where((j) => j.CardType == "项目")
          .toArray();
        if (packageProject.length > 0) {
          var paoj = Enumerable.from(packageProject)
            .where((i) => {
              return (
                !i.ComeQuantity || i.ComeQuantity == "" || i.ComeQuantity == 0
              );
            })
            .count();
          if (paoj != 0) {
            that.submitLoading = false;
            that.$message.error({
              message: "请输入项目转出数量",
              duration: 2000,
            });
            return;
          }

          packageProject = Enumerable.from(packageProject)
            .select((i) => ({
              ProjectAccountID: i.ID,
              Quantity: i.ComeQuantity,
            }))
            .toArray();
        }

        /**  产品  */
        var packageProduct = Enumerable.from(element.ChildItems)
          .where((j) => j.CardType == "产品")
          .toArray();
        if (packageProduct.length > 0) {
          var Product = Enumerable.from(packageProduct)
            .where((i) => {
              return (
                !i.ComeQuantity || i.ComeQuantity == "" || i.ComeQuantity == 0
              );
            })
            .count();
          if (Product != 0) {
            that.submitLoading = false;
            that.$message.error({
              message: "请输入产品转出数量",
              duration: 2000,
            });
            return;
          }

          packageProduct = Enumerable.from(packageProduct)
            .select((i) => ({
              ProductAccountID: i.ID,
              Quantity: i.ComeQuantity,
            }))
            .toArray();
        }

        /**  通用次卡  */
        var packageGeneral = Enumerable.from(element.ChildItems)
          .where((j) => j.CardType == "通用次卡")
          .toArray();
        if (packageGeneral.length > 0) {
          var general = Enumerable.from(packageGeneral)
            .where((i) => {
              return (
                !i.ComeQuantity || i.ComeQuantity == "" || i.ComeQuantity == 0
              );
            })
            .count();
          if (general != 0) {
            that.submitLoading = false;
            that.$message.error({
              message: "请输入通用次卡转出数量",
              duration: 2000,
            });
            return;
          }

          packageGeneral = Enumerable.from(packageGeneral)
            .select((i) => ({
              GeneralCardAccountID: i.ID,
              Quantity: i.ComeQuantity,
            }))
            .toArray();
        }

        /**  储值卡  */
        var packageSaving = Enumerable.from(element.ChildItems)
          .where((j) => j.CardType == "储值卡")
          .toArray();
        if (packageSaving.length > 0) {
          var saving = Enumerable.from(packageSaving)
            .where((i) => {
              return (
                (!i.Amount && !i.LargessAmount) ||
                (i.Amount == "" && i.LargessAmount == "") ||
                (i.Amount == 0 && i.LargessAmount == 0)
              );
            })
            .count();

          console.log("TranserCreate -> saving", saving);

          if (saving != 0) {
            that.submitLoading = false;
            that.$message.error({
              message: "请输入储值卡转出金额",
              duration: 2000,
            });
            return;
          }

          packageSaving = Enumerable.from(packageSaving)
            .select((i) => ({
              SavingCardAccountID: i.ID,
              Amount: i.Amount,
              LargessAmount: i.LargessAmount,
            }))
            .toArray();
        }
        /**  时效卡  */
        var packageTime = Enumerable.from(element.ChildItems)
          .where((i) => i.CardType == "时效卡")
          .select((i) => ({
            TimeCardAccountID: i.ID,
          }))
          .toArray();

        params.PackageCard.push({
          PackageCardAccountID: element.ID,
          Product: packageProduct,
          Project: packageProject,
          GeneralCard: packageGeneral,
          TimeCard: packageTime,
          SavingCard: packageSaving,
        });
      }

      /**  产品  */
      if (
        Enumerable.from(that.ProductCardList)
          .where((i) => {
            return (
              i.ComeQuantity == "" || !i.ComeQuantity || i.ComeQuantity == 0
            );
          })
          .count() != 0
      ) {
        that.submitLoading = false;
        that.$message.error({
          message: "请输入产品转出数量",
          duration: 2000,
        });
        return;
      }

      params.Product = Enumerable.from(that.ProductCardList)
        .select((i) => ({
          ProductAccountID: i.ID,
          Quantity: i.ComeQuantity,
        }))
        .toArray();

      /**  项目  */
      if (
        Enumerable.from(that.ProjectCardList)
          .where((i) => {
            return (
              i.ComeQuantity == "" || !i.ComeQuantity || i.ComeQuantity == 0
            );
          })
          .count() != 0
      ) {
        that.submitLoading = false;
        that.$message.error({
          message: "请输入项目转出数量",
          duration: 2000,
        });
        return;
      }
      params.Project = Enumerable.from(that.ProjectCardList)
        .select((i) => ({
          ProjectAccountID: i.ID,
          Quantity: i.ComeQuantity,
        }))
        .toArray();

      /**  通用次卡  */
      if (
        Enumerable.from(that.GeneralCardList)
          .where((i) => {
            return (
              i.ComeQuantity == "" || !i.ComeQuantity || i.ComeQuantity == 0
            );
          })
          .count() != 0
      ) {
        that.submitLoading = false;
        that.$message.error({
          message: "请输入通用次卡转出数量",
          duration: 2000,
        });
        return;
      }
      params.GeneralCard = Enumerable.from(that.GeneralCardList)
        .select((i) => ({
          GeneralCardAccountID: i.ID,
          Quantity: i.ComeQuantity,
        }))
        .toArray();

      /**时效卡    */
      params.TimeCard = Enumerable.from(that.TimeCardList)
        .select((i) => ({
          TimeCardAccountID: i.ID,
        }))
        .toArray();

      /**  储值卡  */
      var savingNotCount = Enumerable.from(that.SavingCardList)
        .where((i) => {
          return (
            (i.Amount == "" && i.LargessAmount == "") ||
            (!i.Amount && !i.LargessAmount) ||
            (i.Amount == 0 && i.LargessAmount == 0)
          );
        })
        .count();

      if (savingNotCount != 0) {
        that.submitLoading = false;
        that.$message.error({
          message: "请输入储值卡转出金额",
          duration: 2000,
        });
        return;
      }

      params.SavingCard = Enumerable.from(that.SavingCardList)
        .select((i) => ({
          SavingCardAccountID: i.ID,
          Amount: i.Amount,
          LargessAmount: i.LargessAmount | 0,
        }))
        .toArray();

      API.SaveapiTranserCreate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "提交成功",
              duration: 2000,
            });
            that.transerAcount();
            that.addApplyVisible = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
          that.submitLoading = false;
        });
    },
    //点击返回按钮
    goBack() {
      var that = this;
      that.addApplyVisible = false;
    },
    //转账保存
    SaveTranserCreate() {
      var that = this;
      that.Istype = true;
      if (!that.customerID) {
        that.$message.error({
          message: "请选择转出客户",
          duration: 2000,
        });
        return;
      }
      if (!that.TransferInCustomerID) {
        that.$message.error({
          message: "请选择转入客户",
          duration: 2000,
        });
        return;
      }
      if (
        that.ProjectCardList.length == 0 &&
        that.ProductCardList.length == 0 &&
        that.GeneralCardList.length == 0 &&
        that.SavingCardList.length == 0 &&
        that.TimeCardList.length == 0 &&
        that.PackageCardList.length == 0
      ) {
        that.$message.error({
          message: "请选择卡项",
          duration: 2000,
        });
        return;
      }

      that.TranserCreate();
    },
  },
  mounted() {
    var that = this;
    if (localStorage.getItem("access-user")) {
      that.userName = JSON.parse(
        localStorage.getItem("access-user")
      ).EmployeeName;
    }
    if (localStorage.getItem("entityName")) {
      that.entityName = JSON.parse(localStorage.getItem("entityName"));
    }
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // that.SearchData = [new Date(y, m, 1), new Date()];
    that.transerAcount();
  },
};
</script>

  <style  lang="scss">
.Transfer {
  .project_content {
    font-size: 13px;
    height: 100%;
    .project_left {
      border-right: 1px solid #eee;

      color: #303133;
      .el-tabs {
        .el-tabs__header {
          margin: 0;
        }
        .el-tabs__content {
          height: calc(100% - 50px);
          .el-tab-pane {
            height: 100%;
            .col_border {
              border-right: 1px solid #eee;
              padding: 5px 10px;
              color: #666;
              font-size: 13px;
            }
          }
        }
      }
    }
    .project_right {
      height: 100%;
      .el-icon-sort {
        color: #666;
        font-size: 20px;
        transform: rotate(90deg);
      }
      .el-main {
        padding: 0;
        .row_header {
          background-color: #ecf8ff;
          padding: 10px;
        }
        .employee_num {
          width: 90px;
          .el-input-group__append {
            padding: 0 10px;
          }
        }
        .back_project_col {
          background-color: #fafafa;
        }
      }
      .el-footer {
        height: initial !important;
        padding: 10px;
      }
      .itemWidth {
        width: 60%;
      }
    }
  }
  .row_Detalheader {
    background-color: #ecf8ff;
    padding: 10px;
  }

  .el-scrollbar_height {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .dialog_bill_detail {
    background-color: #ecf8ff;
    padding: 15px;
    border-radius: 5px;
  }

  .category {
    height: 100%;
    .category_Project {
      padding-bottom: 20px;
      height: 100%;
    }
    .category_col {
      height: 100%;
      .category_select {
        color: #039be5;
      }
    }
  }
  .consumeGoods {
    .goods-item {
      line-height: 36px;
      font-size: 12px;
      margin-left: 10px;
      margin-right: 5px;
    }
    .goods-lable {
      color: #606266;
    }

    .el-card__header {
      padding: 10px 10px 10px 15px;
      background-color: #f5f7fa;
    }
  }

  .project {
    height: 100%;
    overflow: auto;
    .producct_list {
      height: 100%;
    }
  }
  .transfer {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
  .customer-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .info {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .info {
        color: #ddd;
      }
    }
  }
  .valueCard_project {
    height: 100%;
    .category {
      height: 100%;
      .row_header {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 600;
        color: #333333;
        height: 45px;
        width: 100%;
        // border-bottom: 1px solid #eeeeee;
        // border-right: 1px solid #eeeeee;
        background-color: #ecf8ff;
      }
      .el-scrollbar_height {
        height: 100%;
      }

      .category_select {
        color: #039be5;
      }
    }
  }

  .font_color {
    color: #303133;
  }
}
.popover-package {
  padding: 0px;
  .el-card__header {
    padding: 10px 20px;
    background-color: #f5f7fa;
  }
  .goods-item {
    line-height: 36px;
    font-size: 12px;
    margin-left: 20px;
    margin-right: 5px;
  }
  .goods-lable {
    color: #606266;
  }
}
.customer-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .info {
      font-size: 12px;
      color: #b4b4b4;
    }
    .highlighted .info {
      color: #ddd;
    }
  }
}
</style>